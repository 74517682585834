import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"

const About = (props) => {

    return (
        <Layout>
            <Seo title="About" />
            <h2>About</h2>
            <p class="small">The Romanian born artist Edward Leibovitz has been producing highly individual glass creations since the early 1970s. He combines a purely artistic pursuit and an intense curiosity towards his material with an experimental approach to technique. Over the years he has developed and perfected his own techiques, enabling him to turn his material to his hand.</p>

            <p class="small">He uses a mixture of diamond engraving, carving and sandblasting, and, more recently, laser technology.
                Leibovitz often uses glass in combination with other materials such as bronze and marble, playing the different qualities of the materials off against each other.</p>

            <p class="small">As an artist he has received international recognition, his work having been the subject of several one-man exhibitions, and being included in major museum collections all over the world.</p>
        </Layout>
    )
}

export default About